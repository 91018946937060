import { useState } from "react";
import { Dialog, Typography } from "@mui/material";
import CustomButton from "../Common/CustomButton";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import CustomDialog from "../Common/CustomDialog";

interface LocationPopupProps {
    open: boolean;
    onClose: () => void;
    onLocationFetch: (location: any) => void;
}

const LocationPopup = ({ open, onClose, onLocationFetch }: LocationPopupProps) => {
    const [locationHint, setLocationHint] = useState<boolean>(false);
    const colors = useSelector(selectColors);

    const requestLocationPermission = () => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const location = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                setLocationHint(true);
                onLocationFetch(location); // Pass location to parent (GoogleMapsBlock)
                onClose();
            },
            () => setLocationHint(true)
        );
    };


    return (
        <CustomDialog open={open} onClose={onClose}>
            <div style={{ padding: "16px", textAlign: "center", borderRadius: "8px", marginBottom: "16px" }}>
                <Typography variant="body1" color={colors.gray} gutterBottom>
                    Please enable location functionality for us to automatically fetch your address, or enter it in the prompt.
                </Typography>
                <CustomButton fullWidth onClick={requestLocationPermission} style={{ marginTop: "8px" }}>
                    Enable Location
                </CustomButton>
                {locationHint && (
                    <Typography fontSize={12} color={colors.textMid} style={{ marginTop: "8px" }}>
                        If you did not see a location request popup, please enable browser location permissions for this app.
                    </Typography>
                )}
                <CustomButton fullWidth variant="text" color="secondary" onClick={onClose} style={{ marginTop: "8px" }}>
                    Close
                </CustomButton>
            </div>
        </CustomDialog>
    );
};

export default LocationPopup;