import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/reports";

// =====|  Reports Service  |=====

const ReportsService = {
	getUserSolarInfo: () => http.get(`${apiEndpoint}/store-solar-info`, { headers: getAuthHeader() }),
	saveUserSolarInfo: (reportData: any) => http.post(`${apiEndpoint}/store-solar-info`, reportData),
	getSolarReport: (reportData: any) =>
		http.post(`${apiEndpoint}/onboarding-reports`, reportData, {
			headers: getAuthHeader() ? getAuthHeader() : undefined,
		}),
	getSolarSpecs: (reportData: any) =>
		http.post(`${apiEndpoint}/dashboard-reports`, reportData, { headers: getAuthHeader() }),
	getFinancialImpactSavings: (data: any) =>
		http.post(`${apiEndpoint}/financialimpact-savings`, data, { headers: getAuthHeader() }),
	getFinancialImpactPayment: (data: any) =>
		http.post(`${apiEndpoint}/financialimpact-payment`, data, { headers: getAuthHeader() }),
	getBillAnalysis: (data: any) => http.post(`${apiEndpoint}/bill-analysis`, data, { headers: getAuthHeader() }),
	getPaymentOption: (data: any) => http.post(`${apiEndpoint}/payment-option`, data, { headers: getAuthHeader() }),
	updateUserSolarInfo: (data: any) => http.put(`${apiEndpoint}/store-solar-info`, data, { headers: getAuthHeader() }),
	uploadElectricBill: (data: any) =>
		http.post(`${apiEndpoint}/store-bill`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),
	uploadReportPDF: (data: any) =>
		http.post(`${apiEndpoint}/store-reports-pdf`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),
};

// =====|  APIs  |=====

export const getSolarReport = async (
	monthlyBill: string | number,
	postalCode: string,
	paymentPlan?: string,
): Promise<any | void> => {
	const data = { monthlyBill, postalCode, paymentPlan };
	return ReportsService.getSolarReport(data);
};

export const getUserSolarInfo = async (): Promise<any | void> => {
	return ReportsService.getUserSolarInfo();
};
export const saveUserSolarInfo = async (data: any): Promise<any | void> => {
	return ReportsService.saveUserSolarInfo(data);
};
export const updateUserSolarInfo = async (data: any): Promise<any | void> => {
	return ReportsService.updateUserSolarInfo(data);
};

export const getSolarSpecs = async (monthlyBill: string | number, postalCode: string): Promise<any | void> => {
	const data = { monthlyBill, postalCode };
	return ReportsService.getSolarSpecs(data);
};

export const getFinancialImpactSavings = async (
	monthlyBill: string | number,
	postalCode: string,
): Promise<any | void> => {
	const data = { monthlyBill, postalCode };
	return ReportsService.getFinancialImpactSavings(data);
};

export const getFinancialImpactPayment = async (
	monthlyBill: string | number,
	postalCode: string,
): Promise<any | void> => {
	const data = { monthlyBill, postalCode };
	return ReportsService.getFinancialImpactPayment(data);
};

export const getBillAnalysis = async (
	monthlyBill: string | number,
	delivery_service: string | number,
	supply_charges: string | number,
	uploadedBillCost: string | number,
	postalCode: string,
): Promise<any | void> => {
	const data = { delivery_service, supply_charges, monthlyBill, uploadedBillCost, postalCode };
	return ReportsService.getBillAnalysis(data);
};

export const getPaymentOption = async (monthlyBill: string | number, postalCode: string): Promise<any | void> => {
	const data = { monthlyBill };
	return ReportsService.getPaymentOption(data);
};

export const uploadElectricBill = async (formData: any): Promise<any | void> => {
	return ReportsService.uploadElectricBill(formData);
};

export const uploadReportPDF = async (formData: any): Promise<any | void> => {
	return ReportsService.uploadReportPDF(formData);
};
