import { CloudUploadOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { ReactNode, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import { IconSquareBox } from "../Login/loginStyles";
import { BorderedBox } from "./whyUsStyles";

interface BillUploaderProps {
	onUpdate: any;
	imageFile?: any;
	size?: string | number;
}

interface DashedBoxProps {
	children: ReactNode;
	onClick?: any;
	onDragOver?: any;
	onDrop?: any;
	onDragEnter?: any;
	onDragLeave?: any;
	isDragging: boolean;
}

const DashedBox = ({ children, onDragEnter, onDragLeave, isDragging, ...rest }: DashedBoxProps) => {
	const colors = useSelector(selectColors);
	return (
		<BorderedBox
			sx={{
				borderStyle: "dashed",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				paddingBlock: 34,
				gap: 14,
				width: "100%",
				cursor: "pointer",
				transition: "all ease 0.15s",
				backgroundColor: isDragging ? colors.lightGray : "transparent", // Set the background color based on isDragging
				border: `1px solid ${colors.border}`,
				"&:hover": {
					bgcolor: colors.lightGray,
				},
			}}
			onDragEnter={onDragEnter}
			onDragLeave={onDragLeave}
			{...rest}
		>
			{children}
		</BorderedBox>
	);
};

const BillUploader = ({ onUpdate }: BillUploaderProps) => {
	const colors = useSelector(selectColors);
	const { t } = useTranslation();

	const inputRef = useRef<any>(null);

	const [isDragging, setIsDragging] = useState(false);

	const handleBillUploader = (event: any) => {
		const selectedImage = event.target.files[0];

		if (!selectedImage) return;
		if (selectedImage?.size > 10485760) {
			toast.error(t("BillUploader.fileSizeError"));
		} else {
			onUpdate(selectedImage);
		}
		event.target.value = null; // Resetting the value of the input
	};

	const handleDragOver = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleDragEnter = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setIsDragging(true); // Set isDragging to true when a file is dragged over the box
	};

	const handleDragLeave = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setIsDragging(false); // Set isDragging to false when a file is dragged out of the box
	};

	const handleDrop = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setIsDragging(false);

		const droppedFiles = event.dataTransfer.files;

		if (droppedFiles.length > 0) {
			const selectedFile = droppedFiles[0];

			// Check if the dropped file is a PDF
			if (selectedFile.type === "application/pdf") {
				if (selectedFile.size > 10485760) {
					toast.error(t("BillUploader.fileSizeErrorPdf"));
				} else {
					onUpdate(selectedFile);
				}
			} else {
				toast.error(t("BillUploader.fileTypeError"));
			}
		}
	};

	return (
		<>
			<input
				ref={inputRef}
				type="file"
				name="myImage"
				accept="application/pdf" // TODO: uncomment this line
				onChange={handleBillUploader}
				style={{ display: "none" }}
			/>

			<DashedBox
				onClick={() => inputRef?.current?.click()}
				onDragOver={handleDragOver}
				onDragEnter={handleDragEnter}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				isDragging={isDragging} // Pass the isDragging state to the DashedBox component
			>
				<>
					<IconSquareBox
						sx={{
							border: `1px solid ${colors.border}`,
						}}
					>
						<CloudUploadOutlined />
					</IconSquareBox>
					<Typography variant="h6" fontSize={18} mt={4} color="#000000">
						{t("BillUploader.uploadBillText")}
					</Typography>
					<Typography variant="h6" fontWeight={400} color={colors.textMid}>
						{t("BillUploader.uploadBillSubText")}
					</Typography>
				</>
			</DashedBox>
		</>
	);
};

export default BillUploader;
