import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import { formatNumber } from "../../Utils/utils";
import { DashBorderedBox } from "./solarReportStyles";

interface ValueProp {
	value?: number;
	text?: string;
}

interface DashedBoxWithValuesProps {
	leftValue?: ValueProp;
	rightValue?: ValueProp;
	isWhiteBox?: boolean;
}

const DashedBoxWithValues = ({ leftValue, rightValue, isWhiteBox }: DashedBoxWithValuesProps) => {
	const colors = useSelector(selectColors);
	const { t } = useTranslation();
	return (
		<DashBorderedBox
			sx={{
				border: `1px solid ${colors.border}`,
				...(isWhiteBox ? { border: "none", justifyContent: "center", padding: 0 } : {}),
			}}
		>
			{isWhiteBox ? (
				<Typography variant="h4" textAlign="center" fontSize={{ xs: 18, md: 24 }}>
					{t("DashedBoxWithValues.avoid")} ${formatNumber(leftValue?.value || 0)}{" "}
					{t("DashedBoxWithValues.excessChargesText")}.
				</Typography>
			) : (
				<>
					<Box display="flex" alignItems="center" flexDirection="column" gap={8} minWidth={100}>
						<Typography fontSize={12}>{leftValue?.text}</Typography>
						<Typography variant="h6" fontWeight={600}>
							${formatNumber(leftValue?.value || 0)}
						</Typography>
						<Typography fontSize={12}>{t("DashedBoxWithValues.perMonth")}</Typography>
					</Box>

					<Box sx={{ flexGrow: 1, borderTop: `1px dashed ${colors.border}` }} />

					<Box display="flex" alignItems="center" flexDirection="column" gap={8} minWidth={100}>
						<Typography color="error.main" fontSize={12}>
							{rightValue?.text}
						</Typography>
						<Typography variant="h6" fontWeight={600}>
							${formatNumber(rightValue?.value || 0)}
						</Typography>
						<Typography fontSize={12}>{t("DashedBoxWithValues.perMonth")}</Typography>
					</Box>
				</>
			)}
		</DashBorderedBox>
	);
};

export default DashedBoxWithValues;
