import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "../../Redux/reduxHooks";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import { UserState } from "../../Redux/Slices/userSlice";
import { updateProfile } from "../../Services/profileService";
import OurSolutions from "./OurSolutions";
import { WelcomeBlockHeader } from "./whyUsStyles";

export const HelloBlock = ({ user }: { user?: any }) => {
	const textSnippets = useSelector(selectAllTextSnippets);
	const colors = useSelector(selectColors);

	return (
		<WelcomeBlockHeader sx={{ backgroundColor: colors.lightGray }}>
			<Box zIndex={2} flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
				<Typography
					variant="h4"
					textAlign="center"
					// mb={8}
				>
					{textSnippets["welcome_user_heading"] || "Hello"}, {user?.name}
				</Typography>
				{/* <Typography variant="h6" fontWeight={400} color={colors.textMid}>
			{textSnippets["user_portal_welcome"] ||
				"Welcome to your user portal! Let’s customize your solar system for best results!"}
		</Typography> */}
			</Box>

			<Confetti gravity={0.03} style={{ marginTop: -10 }} />
			{/* <Confetti gravity={0.03} width={250} style={{ marginRight: "auto", marginTop: -10, zIndex: 3 }} />
	<Confetti gravity={0.03} width={250} style={{ marginLeft: "auto", marginTop: -10, zIndex: 4 }} /> */}
		</WelcomeBlockHeader>
	);
};

const WelcomeBlock = ({
	id,
	user,
	satelliteImage,
	streetViewImage,
	data,
}: {
	id?: string;
	user?: UserState;
	satelliteImage?: any;
	streetViewImage?: any;
	data?: any;
}) => {
	const dispatch = useDispatch();
	const textSnippets = useSelector(selectAllTextSnippets);
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	console.log("STATELITE IMG=========", satelliteImage);
	console.log("STREET IMG=========", streetViewImage);

	const [options, setOptions] = useState({
		lowIncome: !!user?.discounts?.lowIncome,
		energyCommunity: user?.discounts?.energyCommunity,
		americanEquipment: user?.discounts?.americanEquipment,
	});

	useEffect(() => {
		setOptions({
			lowIncome: !!user?.discounts?.lowIncome,
			energyCommunity: user?.discounts?.energyCommunity,
			americanEquipment: user?.discounts?.americanEquipment,
		});
	}, [user?.id]);

	const handleCheckbox = async (key: "lowIncome" | "energyCommunity" | "americanEquipment") => {
		const updatedOptions = { ...options, [key]: !options[key] };
		setOptions(updatedOptions);

		try {
			const formData = new FormData();

			formData.append("lowIncome", updatedOptions.lowIncome?.toString() ?? "false");
			formData.append("energyCommunity", updatedOptions.energyCommunity?.toString() ?? "false");
			formData.append("americanEquipment", updatedOptions.americanEquipment?.toString() ?? "false");

			await dispatch(updateProfile(formData));
		} catch (err) {
			toast.error("Something went wrong!");
		}
	};

	return (
		<Box id={id} mt={isSmallScreen ? 20 : 43}>
			{/* {!isSmallScreen && <HelloBlock user={user} />} */}

			{/* <Typography variant={isSmallScreen ? "h5" : "h4"} fontWeight={500} mt={24} mb={20}>
				{textSnippets["user_portal_welcome"] ||
					"Based on the address you provided you may be eligible for the following Additional discounts. Check all discount’s you want to be considered for"}
			</Typography> 		
			<Box
				display="flex"
				alignItems={{ xs: "flex-start", sm: "center" }}
				justifyContent="space-between"
				flexDirection={{ xs: "column", sm: "row" }}
				gap={10}
			>
				<CustomCheckBox
					text={
						textSnippets[" low_income_community_discount"] ||
						`Low Income Community (Up to ${data?.discounts?.lowIncome || 10}%)`
					}
					checked={options.lowIncome}
					onChange={() => handleCheckbox("lowIncome")}
					disabled={!!user?.selectedPlan}
				/>
				<CustomCheckBox
					text={textSnippets["  energy_community_discount"] || `Energy Community (Up to ${data?.discounts?.energyCommunity || 10}%)`}
					checked={options.energyCommunity}
					onChange={() => handleCheckbox("energyCommunity")}
					disabled={!!user?.selectedPlan}
				/>
				<CustomCheckBox
					text={textSnippets["  american_equipment_use"] || `American Equipment Use (Up to ${data?.discounts?.americanEquipment || 10}%)`}
					checked={options.americanEquipment}
					onChange={() => handleCheckbox("americanEquipment")}
					disabled={!!user?.selectedPlan}
				/>
			</Box> */}

			<OurSolutions satelliteImage={satelliteImage} streetViewImage={streetViewImage} />
		</Box>
	);
};

export default WelcomeBlock;
