import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import * as EmailValidator from "email-validator";
import React, { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginPageImage from "../../Assets/Images/login-page-image.png";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useDispatch, useSelector } from "../../Redux/reduxHooks";
import { allRoutes } from "../../Routes/AllRoutes";
import { getAssignedRepresentative, getProfile } from "../../Services/profileService";
import { loginUser } from "../../Services/userService";
import rightContainersColors from "../../Utils/rightContainersColors";
import { requestNotificationPermission } from "../../firebase";
import CustomButton from "../Common/CustomButton";
import CustomCheckBox from "../Common/CustomCheckBox";
import CustomTextField from "../Common/CustomTextField";
import Loader from "../Common/Loader";
import Navbar from "../Navbar/Navbar";
import VerifyEmailDialog from "../SignUp/VerifyEmailDialog";
import FacebookLoginButton from "./FacebookLoginButton";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import GoogleLoginButton from "./GoogleLoginButton";
import { LoginContainer, LoginLeftBlock, LoginRightBlock } from "./loginStyles";

interface DataProps {
	email: string;
	password: string;
}

const Login = () => {
	const { t, i18n } = useTranslation();
	const colors = useSelector(selectColors);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { state }: any = useLocation();
	const newAccountCreated = state?.newAccountCreated;
	const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	const [data, setData] = useState<DataProps>({ email: "", password: "" });
	const [errors, setErrors] = useState<DataProps>({ email: "", password: "" });
	const [loading, setLoading] = useState<boolean>(false);
	const [rememberMe, setRememberMe] = useState<boolean>(false);
	const [forgotPasswordDialog, setForgotPasswordDialog] = useState<boolean>(false);
	const [verificationDialog, setVerificationDialog] = useState<boolean>(false);

	useEffect(() => {
		if (newAccountCreated) {
			openVerificationDialog();
		}
	}, []);

	const openVerificationDialog = () => setVerificationDialog(true);
	const closeVerificationDialog = () => {
		navigate(allRoutes.LOGIN);
		setVerificationDialog(false);
	};

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setData((state) => ({ ...state, [name]: value }));
		setErrors((state) => ({ ...state, [name]: "" }));
	};

	const validateData = () => {
		const updatedErrors = { ...errors };

		updatedErrors.password = data.password ? "" : t("Login.passwordError");
		updatedErrors.email = data.email
			? !EmailValidator.validate(data.email)
				? t("Login.validEmailError")
				: ""
			: t("Login.emailError");

		setErrors(updatedErrors);
		return !Object.values(updatedErrors).find(Boolean);
	};

	const handleLoginSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateData()) return;

		setLoading(true);
		try {
			const newUser: any = await dispatch(loginUser(data));

			if (newUser.access_token === "An Email sent to your account please verify") {
				toast.warn(t("Login.verifyEmail"));
			} else if (newUser) {
				await onLoginSuccess();
			} else {
				onLoginFailure();
			}
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const onLoginSuccess = async () => {
		const { bill, address } = await dispatch(getProfile());
		await dispatch(getAssignedRepresentative());
		await requestNotificationPermission();
		// localStorage.removeItem("channelId")
		localStorage.setItem("monthlyBill", bill);
		localStorage.setItem("userAddress", address);
		toast.success(t("Login.loginSuccessToast"));
		navigate(allRoutes.WHY_CHOOSE_US);
	};

	const onLoginFailure = () => {
		toast.error(t("Login.loginFailedError"));
	};

	const openForgotPasswordDialog = () => setForgotPasswordDialog(true);
	const closeForgotPasswordDialog = () => setForgotPasswordDialog(false);

	return (
		<>
			<Loader open={loading} />
			<Navbar />
			<VerifyEmailDialog open={verificationDialog} onClose={closeVerificationDialog} />
			<LoginContainer>
				<LoginLeftBlock>
					<Typography variant={isMobileView ? "h3" : "h2"}>{t("Login.signInToYourAccount")}</Typography>
					<Typography mt={10} mb={31}>
						{t("Login.signInToYourAccount")}
					</Typography>

					<GoogleLoginButton onSuccess={onLoginSuccess} onFailure={onLoginFailure} />
					<FacebookLoginButton onSuccess={onLoginSuccess} onFailure={onLoginFailure} />

					<div className="fb-like" data-share="true" data-width="450" data-show-faces="true"></div>

					<Divider sx={{ my: 32 }}>
						<Typography px={16} color={colors.textMid}>
							{t("Login.orText")}
						</Typography>
					</Divider>

					<form onSubmit={handleLoginSubmit}>
						<CustomTextField
							name="email"
							label={t("Login.emailLabel")}
							bottom={24}
							value={data.email}
							error={errors.email}
							onChange={handleOnChange}
						/>
						<CustomTextField
							type="password"
							name="password"
							label={t("Login.passwordLabel")}
							bottom={19}
							value={data.password}
							error={errors.password}
							onChange={handleOnChange}
						/>
						<Box display="flex" alignItems="center" justifyContent="space-between" mb={30}>
							<CustomCheckBox
								text={t("Login.rememberMeText")}
								checked={rememberMe}
								onChange={() => setRememberMe(!rememberMe)}
							/>
							<Typography className="link" onClick={openForgotPasswordDialog}>
								{t("Login.forgotPasswordText")}
							</Typography>
						</Box>
						<CustomButton type="submit" fullWidth sx={{ mb: 20 }}>
							{t("Login.signInButtonText")}
						</CustomButton>
					</form>

					<Typography textAlign="center" mt="auto" pt={12}>
						{t("Login.noAccountText")}{" "}
						<span className="link" onClick={() => navigate(allRoutes.SIGN_UP)}>
							{t("Login.signUpButtonText")}
						</span>
					</Typography>
				</LoginLeftBlock>

				<LoginRightBlock>
					<Box
						className="purple-box"
						sx={{
							backgroundColor: rightContainersColors.bgColor,
							"& .login-image": {
								border: `1px solid ${colors.border}`,
							},
						}}
					>
						<img className="login-image" src={LoginPageImage} alt="" />
						<img className="login-image img-2" src={LoginPageImage} alt="" />
						<img className="login-image img-3" src={LoginPageImage} alt="" />
					</Box>
				</LoginRightBlock>
			</LoginContainer>

			<ForgotPasswordDialog open={forgotPasswordDialog} onClose={closeForgotPasswordDialog} />
		</>
	);
};

export default Login;
