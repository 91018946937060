const colors = {
	primary: "#FF6B358",
	primaryLight: "#FFA80080",
	primaryMidLight: "#FFDB96",
	primaryExtraLight: "#FFF6E680",
	primaryLightNew: "#FFF6E6",
	text: "#1C1D34",
	textMid: "#475467",
	border: "#E6E6E6",
	lightGray: "#F6F5F8",
	gray: "#7A7A7A",
	link: "#3581DB",
	success: "#377E36",
	error: "#B12F30",
	successLight: "#19B616",
	errorLight: "#EC3F36",
	successBg: "#E0F0E4",
	errorBg: "#FEECEB",
	darkBg: "#2A223C",
	buttons: "#FF6B35",
};

export default colors;
