import { StyledFooter } from "./footerStyles";
import CustomButton from "../Common/CustomButton";
import { Typography } from "@mui/material";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import { useTranslation } from "react-i18next";

const Footer = ({ onClick, isPlanSelected }: { onClick: () => void; isPlanSelected: boolean }) => {
	const colors = useSelector(selectColors);
	const { t } = useTranslation();

	return (
		<StyledFooter
			className="footer"
			position="static"
			sx={{
				color: colors.text,
				borderTop: `1px solid ${colors.border}`,
			}}
		>
			{!isPlanSelected && <Typography color={colors.gray}>{t("Footer.selectPlanText")}</Typography>}
			<CustomButton sx={{ maxWidth: { sm: "100%", md: "max-content" } }} onClick={onClick} fullWidth>
				{isPlanSelected ? t("Footer.viewProjectText") : t("Footer.skipPlanText")}
			</CustomButton>
		</StyledFooter>
	);
};

export default Footer;
