import { Theme, useMediaQuery } from "@mui/material";
import { Chart } from "react-chartjs-2";
import { selectColors } from "../../../Redux/Slices/generalSlice";
import { useSelector } from "../../../Redux/reduxHooks";
import rightContainersColors from "../../../Utils/rightContainersColors";
import { borderRadius } from "../../../Utils/spacings";
import { formatNumber } from "../../../Utils/utils";

interface DataProps {
	value: number | string;
	label: number | string;
}

interface CustomBarChartProps {
	data?: Array<DataProps>;
}

const CustomBarChart = ({ data = [] }: CustomBarChartProps) => {
	const colors = useSelector(selectColors);
	const labels = data.map((item) => item.label);
	const dataSet = data.map((item) => item.value);

	const maxIndex = data.reduce((maxIndex, currentObj, currentIndex) => {
		const currentValue = parseFloat(currentObj.value?.toString());
		const maxValue = parseFloat(data[maxIndex].value?.toString());
		return currentValue > maxValue ? currentIndex : maxIndex;
	}, 0);

	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

	return (
		<Chart
			type="bar"
			style={{
				width: "100%",
				maxWidth: "100%",

				...(isSmallScreen ? {} : { maxHeight: "calc(100vh - 57px - 70px - 340px - 64px)" }),
			}}
			options={{
				aspectRatio: 1,
				// aspectRatio: isSmallScreen ? 1 : 2.77,
				plugins: {
					legend: { display: false },
					title: { display: false },
					tooltip: {
						titleFont: { size: 18 },
						bodyFont: { size: 14 },
						callbacks: {
							title: (val) => val[0].label,
							label: (val: any) => " $" + formatNumber(val.raw),
						},
					},
				},

				scales: {
					x: {
						border: { display: false },
						grid: { display: false },
						ticks: {
							font: { size: isSmallScreen ? 12 : 14 },
							color: (val): string => (val.index === maxIndex ? colors.primary : "#81839F"),
						},
					},
					y: {
						border: { display: false },
						grid: { display: false },
						ticks: {
							callback: (value: any) => "$" + formatNumber(value),
							font: { size: isSmallScreen ? 12 : 15 },
						},
					},
				},
			}}
			data={{
				labels,
				datasets: [
					{
						label: "",
						data: dataSet,
						backgroundColor: (context) =>
							context.dataIndex === maxIndex ? colors.graphBars : rightContainersColors.bgLightColor,
						hoverBackgroundColor: colors.graphBars,
						barPercentage: 0.7,
						borderSkipped: false,
						borderRadius: borderRadius.sm,
					},
				],
			}}
		/>
	);
};

export default CustomBarChart;
