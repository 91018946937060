import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "welcome-email";

// =====|  Profile Service  |=====

const NotificationOnAcceptProposal = {
	sendNotification: () => http.get(`${apiEndpoint}`, { headers: getAuthHeader() }),
};

export const sendNotification = () => {
	return NotificationOnAcceptProposal.sendNotification();
};
