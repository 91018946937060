import { LogoutOutlined, PersonAddAlt1Outlined, SettingsOutlined } from "@mui/icons-material";
import { Avatar, Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { selectRepresentative } from "../../Redux/Slices/representativeSlice";
import { resetUserState, selectUser } from "../../Redux/Slices/userSlice";
import { allRoutes } from "../../Routes/AllRoutes";
import { isUserLoggedIn, logOut, logoutUser } from "../../Services/userService";
import { sidebarWidths } from "../../Utils/spacings";
import CustomButton from "../Common/CustomButton";
import ReferFriendDialog from "../Common/ReferFriendDialog";
import { StyledDrawer, StyledDrawerBox, StyledDrawerList } from "./SidebarStyles";

interface LayoutSidebarProps {
	open?: boolean;
	handleDrawerToggle?: () => void;
}

const Sidebar = ({ open, handleDrawerToggle }: LayoutSidebarProps) => {
	const colors = useSelector(selectColors);
	const { t } = useTranslation();
	const representative: any = useSelector(selectRepresentative);
	const user: any = useSelector(selectUser);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [dialog, setDialog] = useState(false);

	const openDialog = () => setDialog(true);
	const closeDialog = () => setDialog(false);

	const handleLogout = async () => {
		await logOut();
		logoutUser();
		dispatch(resetUserState());
		// localStorage.removeItem("channelId");
		localStorage.removeItem("anonId");
		localStorage.removeItem("anonUserEmail");
		localStorage.removeItem("anonUserName");
		localStorage.removeItem("chatOpenedOnce");
		// localStorage.clear();
		navigate(allRoutes.ESTIMATE);
	};

	const DrawerBlock = (
		<StyledDrawerBox>
			<Box display="flex" flexDirection={"column"} justifyContent={"space-between"} height={"100%"} p={"24px"}>
				<StyledDrawerList>
					{isUserLoggedIn() && (
						<>
							<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
								<Avatar
									sx={{ width: 110, height: 110, mb: 10, border: `1px solid ${colors.border}` }}
									src={user.picture}
								/>
								<Typography variant={"h4"} fontWeight={600} my={5}>
									{user.name}
								</Typography>
								<Typography>{user.email || "-"}</Typography>
							</Box>

							<Divider sx={{ my: 20 }}></Divider>

							{!!representative.id && (
								<>
									<Typography fontSize={16} color={colors.textMid} mb={10}>
										{t("Sidebar.RepInfo")} :
									</Typography>
									<Typography>
										<b> Name:</b> {representative.name || "-"}
									</Typography>
									<Typography mt={5}>
										<b> Email:</b> {representative.email || "-"}
									</Typography>
									<Typography mt={5}>
										<b> Phone:</b> {representative.phone || "-"}
									</Typography>
								</>
							)}
						</>
					)}
				</StyledDrawerList>
				<Box>
					<CustomButton variant="outlined" color="primary" sx={{ padding: "6px 18px", gap: 8, mb: 12 }} fullWidth>
						<PersonAddAlt1Outlined sx={{ width: 20, height: 20 }} />
						<Typography color="inherit" display={{ xs: "none", sm: "inline-block" }} onClick={openDialog}>
							{t("Navbar.referFriendText")}
						</Typography>
					</CustomButton>

					<Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"space-between"} gap={12}>
						<CustomButton
							variant="outlined"
							color="secondary"
							sx={{ padding: "6px 18px", gap: 8, flexGrow: 1 }}
							onClick={() => navigate(allRoutes.ACCOUNT_SETTINGS)}
						>
							<SettingsOutlined sx={{ width: 20, height: 20 }} />
							<Typography color="inherit" display={{ xs: "none", sm: "inline-block" }}>
								{t("Sidebar.updateInfoText")}
							</Typography>
						</CustomButton>
						<CustomButton
							variant="outlined"
							color="secondary"
							sx={{ padding: "6px 18px", gap: 8, flexGrow: 1 }}
							onClick={handleLogout}
						>
							<LogoutOutlined sx={{ width: 20, height: 20 }} />
							<Typography color="inherit" display={{ xs: "none", sm: "inline-block" }}>
								{t("Sidebar.logoutText")}
							</Typography>
						</CustomButton>
					</Box>
				</Box>
			</Box>
		</StyledDrawerBox>
	);

	return (
		<Box
			component="nav"
			sx={{ width: { xs: sidebarWidths.xs, md: sidebarWidths.md, lg: sidebarWidths.lg }, flexShrink: { sm: 0 } }}
			aria-label="mailbox folders"
		>
			<StyledDrawer
				open={open}
				anchor={"right"}
				variant="temporary"
				onClose={handleDrawerToggle}
				ModalProps={{ keepMounted: true, disableScrollLock: true }} // Better open performance on mobile.
				// sx={{ display: { xs: "block", sm: "none" } }}
			>
				{DrawerBlock}
			</StyledDrawer>
			{/* <StyledDrawer open variant="permanent" sx={{ display: { xs: "none", sm: "block" } }}>
				{DrawerBlock}
			</StyledDrawer> */}
			<ReferFriendDialog open={dialog} onClose={closeDialog} />
		</Box>
	);
};

export default Sidebar;
