import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import {
	Box,
	Button,
	Divider,
	Fab,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	TextField,
	Theme,
	Typography,
	useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StreamChat } from "stream-chat";
import { useDispatch, useSelector } from "../../Redux/reduxHooks";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { selectUser } from "../../Redux/Slices/userSlice";
import { saveVideoClientState } from "../../Redux/Slices/videoSlice";
import { getDefaultMsg } from "../../Services/chatWelcomeMsgConfigService";
import "./ChatWidget.css";

interface Message {
	text: string;
	sender: "user" | "support";
	timestamp: string;
	custom?: any;
}

const formatDate = (date: Date) => {
	const now = new Date();
	const isToday = date.toDateString() === now.toDateString();
	const options: Intl.DateTimeFormatOptions = {
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	};
	if (isToday) {
		return `Today, ${date.toLocaleTimeString(undefined, options)}`;
	} else {
		options.weekday = "short";
		options.month = "short";
		options.day = "numeric";
		return `${date.toLocaleDateString(undefined, options)}, ${date.toLocaleTimeString(undefined, options)}`;
	}
};

const linkify = (text: string, onClick: (url: string) => void) => {
	const specificString = "Please join the video call using the following link:";
	if (text.includes(specificString)) {
		const urlPattern = /(https?:\/\/[^\s]+)/g;
		return text.split(urlPattern).map((part, index) =>
			urlPattern.test(part) ? (
				<Typography
					key={index}
					onClick={() => onClick(part.replace(`${process.env.REACT_APP_CUSTOMER_URL}`, ""))}
					sx={{ color: "primary", cursor: "pointer", textDecoration: "underline" }}
				>
					{"Link to join"}
				</Typography>
			) : (
				part
			),
		);
	}

	const urlPattern = /(https?:\/\/[^\s]+)/g;
	return text.split(urlPattern).map((part, index) =>
		urlPattern.test(part) ? (
			<a key={index} href={part} target="_blank" rel="noopener noreferrer">
				{part}
			</a>
		) : (
			part
		),
	);
};

const ChatWidget: React.FC = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
	const messagesEndRef = useRef<HTMLDivElement>(null);
	const colors = useSelector(selectColors);
	const [options, setOptions] = useState<string[]>([]);
	const [showChatIcon, setShowChatIcon] = useState(true);

	const [isOpen, setIsOpen] = useState(false);
	const [messages, setMessages] = useState<Message[]>([]);
	const [input, setInput] = useState("");
	const [isLiveChat, setIsLiveChat] = useState(false);
	const [channelId, setChannelId] = useState<string | null>(null);
	const logInUser = useSelector(selectUser);
	const [streamClient, setStreamClient] = useState<StreamChat | null>(null);
	const [anonUserId, setAnonUserId] = useState<string | null>(null);

	const [userName, setUserName] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [isUserInfoSubmitted, setIsUserInfoSubmitted] = useState(false);
	const [welcomeMessage, setWelcomeMessage] = useState<string>("");
	const [defaultMessage, setDefaultMessage] = useState<string>("");
	const [disableChat, setDisableChat] = useState<boolean>(false);

	useEffect(() => {
		// Check if the user's information is already submitted by looking for anonUserName in localStorage
		const anonUserName = localStorage.getItem("anonUserName");
		if (anonUserName || logInUser?.id) {
			const savedLanguage = localStorage.getItem("selectedLanguage") || i18n.language;
			fetchWelcomeMessage(savedLanguage);
			setIsUserInfoSubmitted(true);
		} else {
			const savedLanguage = localStorage.getItem("selectedLanguage") || i18n.language;
			if (savedLanguage) {
				fetchWelcomeMessage(savedLanguage);
			} else {
				fetchWelcomeMessage(i18n.language);
			}
		}
	}, [logInUser?.id, i18n.language]);

	useEffect(() => {
		if (isOpen) {
			scrollToBottom();
		}
	}, [messages, isOpen]);

	// Fetch the welcome message from the API
	const fetchWelcomeMessage = async (language: string) => {
		try {
			const queryParams = { language: language };
			const response = await getDefaultMsg(queryParams);
			if (!response?.data) {
				setWelcomeMessage(
					"Hello, I am an AI solar consultant trained to answer your questions. You can request a live chat with an agent at any time. How can I help you today?",
				);
				setDefaultMessage(
					"Hello, I am an AI solar consultant trained to answer your questions. You can request a live chat with an agent at any time. How can I help you today?",
				);
			}
			setWelcomeMessage(response?.data?.msgBeforeChatStart);
			setDefaultMessage(response?.data?.msgAfterChatStart);
		} catch (error) {
			console.error("Error fetching welcome message:", error);
		}
	};
	// New function to setup the channel and listen for messages
	const setupChannel = async (
		client: StreamChat,
		storedChannelId: string,
		userId: string,
		setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
		setIsLiveChat: React.Dispatch<React.SetStateAction<boolean>>,
		setChannelId: React.Dispatch<React.SetStateAction<string | null>>,
	): Promise<void> => {
		const channel = client.channel("messaging", storedChannelId);
		await channel.watch();

		const prevMessages = channel.state.messages;
		setMessages(
			prevMessages.map((msg) => ({
				text: msg.text || "",
				sender: msg.user && msg.user.role === "user" ? "user" : "support",
				timestamp: formatDate(new Date(msg.created_at)),
				custom: msg.custom,
			})),
		);

		setIsLiveChat(true);
		setChannelId(storedChannelId);

		channel.on("message.new", (event) => {
			const message = event.message;
			if (message && message.user && message.user.role === "admin") {
				setMessages((prevMessages) => [
					...prevMessages,
					{
						text: message.text || "No message content",
						sender: "support",
						timestamp: formatDate(new Date(message.created_at || Date.now())),
						custom: message.custom,
					},
				]);

				if (message.text && message.text.includes("Chat ended. Thank you")) {
					setIsLiveChat(false);
					localStorage.removeItem("channelId");
					setMessages((prevMessages) => [
						...prevMessages,
						{
							text: "You have been switched back to the chatbot.",
							sender: "support",
							timestamp: formatDate(new Date()),
						},
					]);
				}
			}
		});
	};

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	const initStreamClient = async () => {
		if (streamClient) return;

		const client = StreamChat.getInstance(`${process.env.REACT_APP_STREAM_API_KEY}`);
		const storedChannelId = localStorage.getItem("channelId");

		// Function to connect user to the Stream client
		const connectUser = async (userId: string, userName: string) => {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/chat/get-token`, { userId });
			const token = response?.data?.data || "";

			await client.connectUser(
				{
					id: userId,
					name: userName,
				},
				token,
			);

			return token; // Return the token for later use
		};

		let userId: string;
		let token: string;

		if (logInUser?.id) {
			userId = logInUser.id.toString();
			token = await connectUser(userId, logInUser?.name || ""); // Use the logged-in user's name

			if (storedChannelId) {
				await setupChannel(client, storedChannelId, userId, setMessages, setIsLiveChat, setChannelId);
			}
		} else {
			const anonId = localStorage.getItem("anonId");
			const userName = localStorage.getItem("anonUserName") || "User"; // Default to "User" if no name is found

			userId = anonId || `anon-${Math.random().toString(36).substr(2, 9)}`;
			if (!anonId) {
				localStorage.setItem("anonId", userId);
				setAnonUserId(userId);
			} else {
				setAnonUserId(anonId);
			}

			token = await connectUser(userId, userName); // Use the anonymous user's name

			if (storedChannelId) {
				await setupChannel(client, storedChannelId, userId, setMessages, setIsLiveChat, setChannelId);
			}
		}

		setStreamClient(client);
		const AnonuserName = logInUser?.name || userName || "User";

		dispatch(
			saveVideoClientState({
				apiKey: `${process.env.REACT_APP_STREAM_API_KEY}`,
				user: {
					id: userId,
					name: AnonuserName,
					image: `https://getstream.io/random_svg/?id=user&name=${AnonuserName}`,
				},
				token: token,
			}),
		);
	};

	const toggleChat = async () => {
		setIsOpen(!isOpen);

		if (!isOpen) {
			// Chat is being opened
			if (isMobile) {
				setShowChatIcon(false);
				document.body.style.overflow = "hidden"; // Hide the chat icon on mobile when chat is opened
			}
			if (logInUser?.id && !localStorage.getItem("chatOpenedOnce")) {
				try {
					// Add the default message as the first message in the chat
					setMessages((prevMessages) => [
						...prevMessages,
						{
							text: defaultMessage,
							sender: "support",
							timestamp: formatDate(new Date()),
						},
					]);

					// Set a flag in localStorage to indicate the message has been shown
					localStorage.setItem("chatOpenedOnce", "true");
				} catch (error) {
					console.error("Error fetching default message:", error);
				}
			}

			if (!logInUser?.id && !isUserInfoSubmitted) {
				// If the user is not logged in and user info is not submitted, show the input fields
				setIsUserInfoSubmitted(false);
			} else {
				initStreamClient();
			}
		} else {
			if (isMobile) {
				setShowChatIcon(true);
				document.body.style.overflow = ""; // Show the chat icon on mobile when chat is closed
			}
			if (streamClient) {
				streamClient.disconnectUser();
				setStreamClient(null);
			}
		}
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setInput(event.target.value);
	};

	const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setUserName(event.target.value);
	};

	const handleUserEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		setUserEmail(event.target.value);
	};

	const handleStartChat = () => {
		if (userName && userEmail) {
			localStorage.setItem("anonUserName", userName);
			localStorage.setItem("anonUserEmail", userEmail);
			setIsUserInfoSubmitted(true);
			// Fetch the default message from the API
			try {
				// Add the default message as the first message in the chat
				setMessages((prevMessages) => [
					...prevMessages,
					{
						text: defaultMessage,
						sender: "support", // Assuming this message is from support
						timestamp: formatDate(new Date()),
					},
				]);
			} catch (error) {
				console.error("Error fetching default message:", error);
			}
			initStreamClient();
		}
	};

	const handleSendMessage = async () => {
		if (input.trim() === "") return;

		const newMessage: Message = {
			text: input,
			sender: "user",
			timestamp: formatDate(new Date()),
		};

		setMessages((prevMessages) => [...prevMessages, newMessage]);
		setInput("");

		try {
			let userId: string;
			const dataSource = logInUser?.id ? "database" : "localStorage"; // Changed to const
			let anonUserData = null;

			if (logInUser?.id) {
				userId = logInUser.id.toString();
			} else {
				anonUserData = JSON.parse(localStorage.getItem("userReportData") || "{}");
				userId = anonUserId || "";
				const exisitngAnonUsername = localStorage.getItem("anonUserName");
				const exisitngAnonUseremail = localStorage.getItem("anonUserEmail");

				if (!exisitngAnonUsername && !exisitngAnonUseremail) {
					localStorage.setItem("anonUserName", userName);
					localStorage.setItem("anonUserEmail", userEmail);
				}
			}

			if (isLiveChat && streamClient && channelId) {
				localStorage.setItem("channelId", channelId);
				const channel = streamClient.channel("messaging", channelId);
				await channel.sendMessage({ text: input, user_id: userId });
			} else {
				const userName = localStorage.getItem("anonUserName") || logInUser?.name || "";

				const response = await axios.post(`${process.env.REACT_APP_API_URL}/chat`, {
					query: input,
					userId,
					dataSource,
					anonUserData,
					userName,
					language: localStorage.getItem("selectedLanguage") || i18n.language,
				});

				await handleResponse(response);
			}
		} catch (error) {
			console.error("Error sending message:", error);
		}
	};

	// Function to handle response from the server
	const handleResponse = async (response: any) => {
		if (response?.data?.data?.liveChat && response?.data?.data?.channelId) {
			setIsLiveChat(true);
			const newChannelId = response.data.data.channelId;
			setChannelId(newChannelId);

			if (streamClient) {
				const channel = streamClient.channel("messaging", newChannelId);
				await channel.watch();

				setMessages((prevMessages) => [
					...prevMessages,
					{
						text: response?.data?.data?.message,
						sender: "support",
						timestamp: formatDate(new Date()),
					},
				]);

				channel.on("message.new", (event) => {
					handleNewMessage(event.message);
				});
			}
		} else if (response.data?.data?.message instanceof Array) {
			// Store options in state if the response is an array of options
			setOptions(response.data.data.message);
			setDisableChat(true);
		} else {
			setMessages((prevMessages) => [
				...prevMessages,
				{
					text: response.data?.data?.answer,
					sender: "support",
					timestamp: formatDate(new Date()),
				},
			]);
		}
	};

	// Function to handle user's option selection
	const handleOptionSelect = async (selectedOption: string) => {
		// Clear options once an option is selected
		setDisableChat(false);
		const userName = logInUser?.name || "";
		setOptions([]);
		setMessages((prevMessages) => [
			...prevMessages,
			{
				text: selectedOption,
				sender: "user",
				timestamp: formatDate(new Date()),
			},
		]);

		// Send selected option to API to initialize live chat
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/chat`, {
				query: selectedOption,
				userId: logInUser?.id,
				userName,
				language: localStorage.getItem("selectedLanguage") || i18n.language,
			});
			await handleResponse(response);
		} catch (error) {
			console.error("Error selecting option:", error);
		}
	};

	// Function to handle new incoming messages
	const handleNewMessage = (message: any) => {
		if (message && message.user && message.user.role === "admin") {
			setMessages((prevMessages) => [
				...prevMessages,
				{
					text: message.text || "No message content",
					sender: "support",
					timestamp: formatDate(new Date(message.created_at || Date.now())),
					custom: message.custom,
				},
			]);

			if (message.text?.includes("Chat ended. Thank you")) {
				setIsLiveChat(false);
				localStorage.removeItem("channelId");
				setMessages((prevMessages) => [
					...prevMessages,
					{
						text: i18n.language === "es" ? "Has vuelto al chatbot." : "You have been switched back to the chatbot.",
						sender: "support",
						timestamp: formatDate(new Date()),
					},
				]);
			}
		}
	};

	const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			handleSendMessage();
		}
	};

	const handleLinkClick = (url: string) => {
		navigate(url);
	};

	return (
		<Box
			className="chat-container"
			sx={{
				position: "fixed",
				bottom: isMobile ? 0 : 16,
				right: isMobile ? 0 : 16,
				width: isMobile && isOpen ? "100%" : "auto",
				// height: 'auto',
				justifyContent: isMobile ? "end" : "",
			}}
		>
			{isOpen && (
				<Paper
					elevation={3}
					sx={{
						width: isMobile ? "100%" : 350,
						height: isMobile ? "calc(100svh - 12px)" : "auto",
						// height: isMobile ? '98svh' : 'auto',
						mb: 2,
						borderRadius: isMobile ? 0 : 2,
					}}
				>
					<Box
						className="chat-header"
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							p: 8,
							bgcolor: "primary.main",
							color: "white",
							height: isMobile ? "5%" : "auto",
							borderTopLeftRadius: isMobile ? 0 : 2,
							borderTopRightRadius: isMobile ? 0 : 2,
						}}
					>
						<Typography variant="h6">Terawatt Support Team</Typography>
						<IconButton size="small" sx={{ color: "white" }} onClick={toggleChat}>
							<CloseIcon />
						</IconButton>
					</Box>
					<Box
						className="chat-body"
						sx={{
							display: "flex",
							flexDirection: "column",
							flex: 1,
							overflowY: "auto",
							// height: isMobile ? 'calc(100vh - 112px)' : 400,
							height: isMobile ? "95%" : 400,
						}}
					>
						{/* Render user info input fields if user is not logged in and not submitted */}
						{!logInUser?.id && !isUserInfoSubmitted && (
							<Box sx={{ p: 6, my: 6, mt: 20 }}>
								{welcomeMessage && (
									<Box
										sx={{
											display: "flex",
											justifyContent: "center", // Center the bubble horizontally
											mb: 20, // Add some margin at the bottom
										}}
									>
										<Box
											sx={{
												width: "100%", // Make the bubble take the full width
												bgcolor: colors.lightGray,
												color: colors.text,
												borderRadius: 16, // Make the corners more rounded
												p: 2,
												boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", // Add a subtle shadow for better visibility
											}}
										>
											<Typography variant="body1" align="center">
												{" "}
												{/* Center the text within the bubble */}
												{welcomeMessage}
											</Typography>
										</Box>
									</Box>
								)}

								<TextField
									fullWidth
									label={t("ChatWidget.nameLabel")}
									variant="outlined"
									value={userName}
									onChange={handleUserNameChange}
									sx={{ mb: 5 }}
								/>
								<TextField
									fullWidth
									label={t("ChatWidget.emailLabel")}
									variant="outlined"
									value={userEmail}
									onChange={handleUserEmailChange}
									sx={{ mb: 5, mt: 5 }}
								/>
								<Button variant="contained" color="primary" fullWidth onClick={handleStartChat} sx={{ mt: 5 }}>
									{t("ChatWidget.startChatText")}
								</Button>
							</Box>
						)}
						{isUserInfoSubmitted && (
							<>
								<List className="messages" sx={{ flex: 1, overflowY: "auto", p: 2 }}>
									{messages.map((message, index) => (
										<ListItem
											key={index}
											className={message.sender === "user" ? "message user" : "message support"}
											sx={{
												alignItems: "flex-start",
												justifyContent: message.sender === "user" ? "flex-end" : "flex-start",
												display: "flex",
												flexDirection: "column",
												bgcolor: message.sender === "user" ? colors.primaryLight : colors.lightGray,
												color: colors.text,
												borderRadius: 10,
												p: 8,
												m: 1,
												maxWidth: "70%",
												margin: "10px 0 0 10px ",
											}}
										>
											<ListItemText primary={linkify(message.text, handleLinkClick)} />
											<Typography variant="caption" sx={{ mt: 0.5, color: "gray" }}>
												{message.timestamp}
											</Typography>
										</ListItem>
									))}

									{options && (
										<Box
											sx={{
												bgcolor: colors.lightGray, // Light background color for the box
												borderRadius: 2,
												boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow
												p: 3,
												m: 2,
												maxWidth: "80%",
												mt: 10,
											}}
										>
											{options.map((option, index) => (
												<ListItem
													key={index}
													className="message support"
													sx={{
														alignItems: "flex-start",
														justifyContent: "flex-start",
														display: "flex",
														flexDirection: "column",
														bgcolor: colors.lightGray,
														color: colors.text,
														borderRadius: 10,
														p: 8,
														m: 1,
														maxWidth: "70%",
														margin: "10px 0 0 10px ",
													}}
												>
													<ListItemText
														primary={
															<Typography
																variant="body1"
																onClick={() => handleOptionSelect(option)}
																sx={{
																	cursor: "pointer",
																	"&:hover": { textDecoration: "underline", color: "primary.main" },
																}}
															>
																{option}
															</Typography>
														}
													/>
												</ListItem>
											))}
										</Box>
									)}

									<div ref={messagesEndRef} />
								</List>
								<Divider />
								<Box
									className="chat-input"
									sx={{
										display: "flex",
										alignItems: "center",
										p: 2,
									}}
								>
									<TextField
										fullWidth
										variant="outlined"
										size="small"
										value={input}
										onChange={handleInputChange}
										onKeyPress={handleKeyPress}
										placeholder="Type your message..."
										disabled={disableChat}
										sx={{ bgcolor: "white", borderRadius: 1 }}
									/>
									<IconButton disabled={disableChat} onClick={handleSendMessage} color="primary">
										<SendIcon />
									</IconButton>
								</Box>
							</>
						)}
					</Box>
				</Paper>
			)}
			{showChatIcon && (
				<Fab
					className="chat-btn"
					sx={{ width: "50.8px", height: "50.8px" }}
					color="primary"
					aria-label="chat"
					onClick={toggleChat}
				>
					<ChatIcon />
				</Fab>
			)}
		</Box>
	);
};

export default ChatWidget;
