import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import useScrollPercentage from "../../Hooks/useScrollPercentage";
import { useDispatch, useSelector } from "../../Redux/reduxHooks";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import { saveAddressObject, saveBill, savePostalCode, selectAddress, selectUser } from "../../Redux/Slices/userSlice";
import { allRoutes } from "../../Routes/AllRoutes";
import { verifyAddress } from "../../Services/addressService";
import { getSolarReport } from "../../Services/reportService";
import { decodeReferralLink } from "../../Services/userService";
import { HomeImage, ReportIcon1, ReportIcon2, ReportIcon3, ReportIcon4 } from "../../Utils/Images";
import rightContainersColors from "../../Utils/rightContainersColors";
import { decimalFreeNumber, formatNumber, getSatelliteImage, getStreetViewImage } from "../../Utils/utils";
import CustomDoughnutChart from "../Common/Charts/CustomDoughnutChart";
import CustomButton from "../Common/CustomButton";
import Loader from "../Common/Loader";
import Navbar from "../Navbar/Navbar";
import UtilityBillIncreasing, { BillData } from "../WhyUs/UtilityBillIncreasing";
import { HelloBlock } from "../WhyUs/WelcomeBlock";
import BarWithValue, { DoubleBarsWithValue } from "./BarWithValue";
import DashedBoxWithValues from "./DashedBoxWithValues";
import QualificationDialog from "./QualificationDialog";
import { GrayBlock, SolarReportContainer, SolarReportRightColumn } from "./solarReportStyles";
// const { TranslationServiceClient } = require('@google-cloud/translate').v3;

const SolarReport = ({
	forDashboard,
	dashboardData,
	detailDesignImgUrl,
}: {
	forDashboard?: boolean;
	dashboardData?: any;
	detailDesignImgUrl?: string;
}) => {
	const { t, i18n } = useTranslation();
	const colors = useSelector(selectColors);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const userAddress = useSelector(selectAddress);
	const scrollPercentage = useScrollPercentage();
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
	const [searchParams] = useSearchParams();

	// TODO in future: create PageLayout and move Navbar in that component and also move page padding in that as well and handle responsive padding there

	const [showDialog, setShowDialog] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [yearlyBills, setYearlyBills] = useState<Array<BillData>>([]);
	const [data, setData] = useState<any>({});

	// Fetching Text Snippets From Redux
	const textSnippets = useSelector(selectAllTextSnippets);

	useEffect(() => {
		window.scrollTo(0, 0);

		const referralLink = searchParams.get("referral-link");
		if (referralLink) {
			getDataOfReferralLink(referralLink);
		} else if ((!user.address || !user.bill) && !forDashboard) {
			navigate(allRoutes.ESTIMATE);
		} else {
			fetchSolarReportData();
		}
	}, [dashboardData]);

	const getDataOfReferralLink = async (referralLink: string) => {
		if (referralLink) {
			setLoading(true);
			try {
				const { data: decoded } = await decodeReferralLink(referralLink);

				if (decoded?.address) {
					const { data }: any = await verifyAddress(decoded?.address ?? "");

					dispatch(
						saveAddressObject({
							description: decoded.address,
							structured_formatting: {
								main_text: decoded.address,
								secondary_text: decoded.address,
							},
						}),
					);
					dispatch(saveBill(decoded.bill));

					if (
						[
							"We are not providing services for this address yet. Please try again in future.",
							"Not a US address",
							"Something went wrong",
						].includes(data)
					) {
						toast.warn(t("Estimate.notOperatingMessage"));
						navigate(allRoutes.ESTIMATE);
					} else if (data.isValid) {
						// storing address and bill in redux for future use

						dispatch(savePostalCode(data.postalCode as string));

						await fetchSolarReportData();
					} else {
						toast.error(t("Estimate.invalidAddressMessage"));
						navigate(allRoutes.ESTIMATE);
					}
				}
			} catch (error: any) {
				toast.error(error);
			}
			setLoading(false);
		}
	};

	const fetchSolarReportData = async () => {
		if (forDashboard) {
			return handleSetValues(dashboardData);
		}

		setLoading(true);
		try {
			const { data: response } = await getSolarReport(
				user.bill || "200",
				user.postalCode as string,
				user.selectedPlan === "Lease" ? user.selectedPlan : "",
			);
			handleSetValues(response);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleSetValues = (response: any) => {
		setData(response);
		setYearlyBills(response?.yearlyBills || []);
		if (!user.id) {
			localStorage.setItem(
				"userReportData",
				JSON.stringify({
					systemSizeKW: response?.SystemSizeKW,
					panelCount: response?.panelCount,
					systemProduction: response?.systemProduction,
					monthlyBillWithSolar: decimalFreeNumber(response?.solarSavingMonth1),
					solarSaving25Years: decimalFreeNumber(response?.solarSavingYear25),
					address: userAddress,
					monthlyBill: response?.monthlyBill,
					currentStep: "Accepted Proposal",
					electricityBillWithOutSolar25Years: decimalFreeNumber(response?.billAfter20Years),
					monthlyResidualElectricBill: decimalFreeNumber(response?.UtilityBillAfterSolar),
					residualBill25Years: decimalFreeNumber(response?.totalResidualBill25Years),
					solarProductionPercentage: response?.SolarOffset,
					solarSystemPrice: response?.systemPrice,
				}),
			);
		}
	};

	const handleNext = () => {
		// navigate(allRoutes.SIGN_UP);
		openDialog();
	};

	const openDialog = () => setShowDialog(true);
	const closeDialog = () => setShowDialog(false);

	const maxHeightOfBlock = data?.UtilityBillAfterSolar > 0 ? 360 : 300;
	const heightOfElectricBlock =
		(data?.totalAnnualBill20years /
			(data?.totalAnnualBill20years +
				(data?.UtilityBillAfterSolar > 0 ? data?.totalResidualBill25Years : 0) +
				data?.switchToSolar || 1)) *
			maxHeightOfBlock || 250;
	const heightOfSavingsBlock =
		(data?.switchToSolar /
			(data?.totalAnnualBill20years +
				(data?.UtilityBillAfterSolar > 0 ? data?.totalResidualBill25Years : 0) +
				data?.switchToSolar || 1)) *
			maxHeightOfBlock || 250;
	const heightOfResidualBlock =
		((data?.totalResidualBill25Years /
			(data?.totalAnnualBill20years + data?.switchToSolar + data?.totalResidualBill25Years || 1)) *
			maxHeightOfBlock || 250) + heightOfSavingsBlock;

	const displayFirstElement = isSmallScreen ? true : scrollPercentage < (forDashboard ? 20 : 33);
	const displaySecondElement = isSmallScreen
		? true
		: scrollPercentage >= (forDashboard ? 20 : 33) && scrollPercentage < (forDashboard ? 40 : 66);
	const displayThirdElement = isSmallScreen ? true : scrollPercentage >= (forDashboard ? 40 : 66);
	// const displayFirstElement = isSmallScreen ? true : scrollPercentage < 33;
	// const displaySecondElement = isSmallScreen ? true : scrollPercentage >= 33 && scrollPercentage < 66;
	// const displayThirdElement = isSmallScreen ? true : scrollPercentage >= 66;

	// const annualBill1month = data?.billAfter1Year ?? 0;
	// const annualBill20years = data?.monthlyBill20Years * 12 ?? 0; // TODO: will handle it later on BE side
	// const annualBill20years = data?.billAfter20Years ?? 0;

	const doughnutChartData = [
		{ value: data?.SolarOffset, label: t("SolarReport.solarProductionText"), color: colors.graphBars },
		{ value: data?.UtilityUsage, label: t("SolarReport.utilityUsageText"), color: "white" },
	];

	return (
		<>
			{!forDashboard && <Navbar />}
			<Loader open={loading} />

			<SolarReportContainer id="summary-report" forDashboard={forDashboard}>
				<Box>
					{/* {forDashboard && isSmallScreen ? ( */}
					{forDashboard ? (
						<HelloBlock user={user} />
					) : (
						<>
							<Typography variant="h4">{textSnippets.solar_appeal_message || "Solar Looks Good On You!"}</Typography>
							<Divider sx={{ mt: { xs: 12, sm: 20 } }} />
						</>
					)}
					<Typography variant="h5" mb={10} mt={24}>
						{textSnippets.your_house_label || "Your House"}
					</Typography>
					{/* <img
						className="left-image"
						src={isSmallScreen ? getStreetViewImage(user.address) : getSatelliteImage(user.address)}
						alt=""
					/> */}
					<img
						className="left-image"
						src={detailDesignImgUrl ? detailDesignImgUrl : getStreetViewImage(user.address) || HomeImage}
						alt=""
					/>
					<Typography variant="body2" mt={10}>
						{user.address || "Your house address"}
					</Typography>
					<Typography variant="h5" mt={{ xs: 24, sm: 30 }}>
						{textSnippets.electric_bill_label || "Your Current Bill"}
					</Typography>

					<GrayBlock sx={{ backgroundColor: colors.lightGray }} mt={16}>
						{isSmallScreen ? (
							<>
								<Box display="flex" alignItems="center" justifyContent="space-between" gap={10}>
									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600}>
											{textSnippets.current_monthly_bill || "Monthly Bill"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(user.bill || data?.monthlyBill)}
										</Typography>
									</Box>
									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600}>
											{textSnippets.yearly_electricity_expense || "Annual Bill"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(data?.billAfter1Year)}
										</Typography>
									</Box>
								</Box>

								<Box display="flex" flexDirection="column" alignItems="center" gap={6} mt={42}>
									<Typography variant="h6" fontWeight={600}>
										{textSnippets.estimated_25yr_electricity || "25 Years Expense"}
									</Typography>
									<Typography variant="h2" fontWeight={500} color="error.main">
										${formatNumber(data?.billAfter20Years)}
									</Typography>
								</Box>
							</>
						) : (
							<>
								<Box display="flex" alignItems="center" justifyContent="space-between" gap={10}>
									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600}>
											{t("SolarReport.monthlyBillText")}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(user.bill || data?.monthlyBill)}
										</Typography>
									</Box>
									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600}>
											{textSnippets.yearly_electricity_expense || "Annual Bill"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(data?.billAfter1Year)}
										</Typography>
									</Box>
								</Box>

								<Box display="flex" flexDirection="column" alignItems="center" gap={6} mt={42}>
									<Typography variant="h6" fontWeight={600}>
										{textSnippets.estimated_25yr_electricity || "25 Years Expense"}
									</Typography>
									<Typography variant="h2" fontWeight={500} color="error.main">
										${formatNumber(data?.billAfter20Years)}
									</Typography>
								</Box>
							</>
						)}
					</GrayBlock>

					{isSmallScreen && (
						<>
							<GrayBlock sx={{ backgroundColor: colors.lightGray }} my={16}>
								<UtilityBillIncreasing mt={0} yearlyBills={yearlyBills} />
							</GrayBlock>
							<Box
								component="img"
								className="left-image"
								src={
									isSmallScreen
										? detailDesignImgUrl
											? detailDesignImgUrl
											: getStreetViewImage(user.address)
										: getSatelliteImage(user.address) || HomeImage
								}
								alt=""
								sx={{ height: "173px !important" }}
							/>
						</>
					)}

					<GrayBlock sx={{ backgroundColor: colors.lightGray }} mt={18}>
						<Typography variant="h6" fontWeight={600}>
							{textSnippets.solar_system_label || "Your Solar System"}
						</Typography>
						<Typography variant="h2" fontWeight={500} mt={16} mb={isSmallScreen ? 16 : 24}>
							{data?.SystemSizeKW?.toLocaleString()}kW Solar
						</Typography>
						{/* <Typography mb={6} color="text.secondary">
							{textSnippets.solar_system_power_desc ||
								"This is the power of your solar system, sometimes referred to as the system size"}
						</Typography> */}
						{isSmallScreen ? (
							<Box display="flex" alignItems="center" justifyContent="space-between" gap={6}>
								<Typography fontWeight={500}>{textSnippets.system_production || "Producing"}</Typography>
								<Typography color="success.main">{formatNumber(data?.systemProduction)} kW/h</Typography>
							</Box>
						) : (
							// <BillBlock
							// 	hideIcon
							// 	title={textSnippets.system_production || "System production"}
							// 	bill={formatNumber(data?.systemProduction)}
							// 	customUnit=" kW/h"
							// />
							<Box display="flex" alignItems="center" justifyContent="space-between" gap={6}>
								<Typography fontWeight={500}>{textSnippets.system_production || "Producing"}</Typography>
								<Typography color="success.main">{formatNumber(data?.systemProduction)} kW/h</Typography>
							</Box>
						)}

						<Typography variant="h6" fontWeight={600} mt={isSmallScreen ? 32 : 14} mb={36}>
							{textSnippets.electricity_usage_label || "Electricity Usage"}
						</Typography>

						<Box width={192} mx={"auto"}>
							<CustomDoughnutChart displayingValue={data?.SolarOffset} dataSets={doughnutChartData} />
						</Box>

						<Box display="flex" alignItems="center" justifyContent="space-between" mt={35} gap={10}>
							<Typography variant="h6" fontWeight={600}>
								{textSnippets.utility_usage_label || "Utility Usages"}: {formatNumber(data?.UtilityUsage)}%
							</Typography>
							<Typography variant="h6" fontWeight={600}>
								{textSnippets.solar_usage_label || "Solar Production"}:{" "}
								<Typography variant="inherit" fontWeight="inherit" component="span" color="success.main">
									{formatNumber(data?.SolarOffset)}%
								</Typography>
							</Typography>
						</Box>

						{/* <BillBlock
							hideIcon
							title={
								<span>
									{formatNumber(data?.panelCount)} Q Cell 400 W Solar Panels <br />
									{formatNumber(data?.panelCount)} Enphase Micro Inverters
								</span>
							}
							mt={35}
						/> */}
						<Typography variant="h6" fontWeight={600} mt={32} mb={36}>
							{textSnippets.no_pocket_expenses || "No Out of Pocket Expenses"}
						</Typography>
						<Box
							display="flex"
							flexDirection="column"
							gap={20}
							sx={{
								"& svg": {
									"& path": {
										fill: colors.primary,
									},
								},
							}}
						>
							<Box display="grid" gridTemplateColumns="19px 1fr" gap={10}>
								<ReportIcon1 />
								{/* <Box component="img" src={ReportIcon1} /> */}
								<Typography variant="h6">{t("SolarReport.comprehensiveServiceOffering")}</Typography>
							</Box>
							<Box display="grid" gridTemplateColumns="19px 1fr" gap={10}>
								<ReportIcon4 />
								{/* <Box component="img" src={ReportIcon4} /> */}
								<Typography variant="h6">{t("SolarReport.monitoringMaintenanceRepairs")}</Typography>
							</Box>
							<Box display="grid" gridTemplateColumns="19px 1fr" gap={10}>
								<ReportIcon2 />
								{/* <Box component="img" src={ReportIcon2} /> */}
								<Typography variant="h6">{t("SolarReport.energyGuaranteeText")}</Typography>
							</Box>
							<Box display="grid" gridTemplateColumns="19px 1fr" gap={10}>
								<ReportIcon3 />
								{/* <Box component="img" src={ReportIcon3} /> */}
								<Typography variant="h6">{t("SolarReport.noOutOfPocketCostText")}</Typography>
							</Box>
						</Box>
					</GrayBlock>

					{isSmallScreen ? (
						<>
							<Typography variant="h5" mt={{ xs: 24, sm: 30 }}>
								{t("SolarReport.withSolarLabel")}
								{/* {textSnippets.electric_bill_label || "With Solar"} */}
							</Typography>

							<GrayBlock sx={{ backgroundColor: colors.lightGray }} mt={16}>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									columnGap={10}
									rowGap={42}
									flexWrap={"wrap"}
									sx={{ "& > div": { width: "40%" } }}
								>
									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600} textAlign="center">
											{textSnippets.monthly_bill_with_solar || "Monthly bill with solar"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											$
											{user.selectedPlan === "Lease"
												? formatNumber(decimalFreeNumber(data?.solarSavingMonth1Lease))
												: formatNumber(decimalFreeNumber(data?.solarSavingMonth1))}
										</Typography>
									</Box>

									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600} textAlign="center">
											{/* {textSnippets.first_year_savings || "First Year Saving"} */}
											{t("SolarReport.residualBillLabel")}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(decimalFreeNumber(data?.UtilityBillAfterSolar))}
										</Typography>
									</Box>

									<Box
										display="flex"
										flexDirection="column"
										alignItems="center"
										gap={6}
										mx="auto"
										width="100% !important"
									>
										<Typography variant="h6" fontWeight={600} textAlign="center">
											{textSnippets.first_year_savings || "First Year Saving"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											$
											{user.selectedPlan === "Lease"
												? formatNumber(decimalFreeNumber(data?.solarSavingYear1Lease))
												: formatNumber(decimalFreeNumber(data?.solarSavingYear1))}
										</Typography>
									</Box>
								</Box>

								<Box display="flex" flexDirection="column" alignItems="center" gap={6} mt={42}>
									<Typography variant="h6" fontWeight={600}>
										{textSnippets.avoided_25yr_expense || "Avoided Expenses over 25 years"}
									</Typography>
									<Typography variant="h2" fontWeight={500} color="success.main">
										$
										{user.selectedPlan === "Lease"
											? formatNumber(decimalFreeNumber(data?.solarSavingYear25Lease))
											: formatNumber(decimalFreeNumber(data?.solarSavingYear25))}
									</Typography>
								</Box>
							</GrayBlock>
						</>
					) : (
						<>
							<Typography variant="h5" mt={{ xs: 24, sm: 30 }}>
								{t("SolarReport.withSolarLabel")}
								{/* {textSnippets.electric_bill_label || "With Solar"} */}
							</Typography>

							<GrayBlock sx={{ backgroundColor: colors.lightGray }} mt={16}>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									columnGap={10}
									rowGap={42}
									flexWrap={"wrap"}
									sx={{ "& > div": { width: "40%" } }}
								>
									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600} textAlign="center">
											{textSnippets.monthly_bill_with_solar || "Monthly bill with solar"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											$
											{user.selectedPlan === "Lease"
												? formatNumber(decimalFreeNumber(data?.solarSavingMonth1Lease))
												: formatNumber(decimalFreeNumber(data?.solarSavingMonth1))}
										</Typography>
									</Box>

									<Box display="flex" flexDirection="column" alignItems="center" gap={6}>
										<Typography variant="h6" fontWeight={600} textAlign="center">
											{/* {textSnippets.first_year_savings || "First Year Saving"} */}
											{t("SolarReport.residualBillLabel")}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											${formatNumber(decimalFreeNumber(data?.UtilityBillAfterSolar))}
										</Typography>
									</Box>

									<Box
										display="flex"
										flexDirection="column"
										alignItems="center"
										gap={6}
										mx="auto"
										width="100% !important"
									>
										<Typography variant="h6" fontWeight={600} textAlign="center">
											{textSnippets.first_year_savings || "First Year Saving"}
										</Typography>
										<Typography variant="h2" fontWeight={500}>
											$
											{user.selectedPlan === "Lease"
												? formatNumber(decimalFreeNumber(data?.solarSavingYear1Lease))
												: formatNumber(decimalFreeNumber(data?.solarSavingYear1))}
										</Typography>
									</Box>
								</Box>

								<Box display="flex" flexDirection="column" alignItems="center" gap={6} mt={42}>
									<Typography variant="h6" fontWeight={600}>
										{textSnippets.avoided_25yr_expense || "Avoided Expenses over 25 years"}
									</Typography>
									<Typography variant="h2" fontWeight={500} color="success.main">
										$
										{user.selectedPlan === "Lease"
											? formatNumber(decimalFreeNumber(data?.solarSavingYear25Lease))
											: formatNumber(decimalFreeNumber(data?.solarSavingYear25))}
									</Typography>
								</Box>
							</GrayBlock>
						</>
					)}

					{!isSmallScreen &&
						(!forDashboard ? (
							<CustomButton fullWidth onClick={handleNext} sx={{ mt: 24 }}>
								{textSnippets.unlock_proposal_button || "See if I qualify"}
							</CustomButton>
						) : (
							<Box></Box>
						))}
				</Box>
				{!isSmallScreen && (
					<SolarReportRightColumn
						id="right-col"
						forDashboard={forDashboard}
						sx={{ backgroundColor: rightContainersColors.bgColor }}
					>
						{displayFirstElement && (
							<Box
								className="fade-in-block"
								sx={{
									px: { xs: 20, sm: 40 },
									py: 10,
									my: "auto",
								}}
							>
								<UtilityBillIncreasing mt={0} yearlyBills={yearlyBills} />
							</Box>
						)}
						{displaySecondElement && (
							<img
								className="right-image fade-in-block"
								src={
									isSmallScreen
										? getStreetViewImage(user.address)
										: detailDesignImgUrl
										? detailDesignImgUrl
										: getSatelliteImage(user.address)
								}
								alt=""
							/>
						)}
						{displayThirdElement && (
							<Box className="fade-in-block" sx={{ p: { xs: 20, sm: 40 }, my: "auto" }}>
								<Box display="flex" alignItems="flex-end" justifyContent="center" gap={{ xs: 20, sm: 70 }}>
									{data?.UtilityBillAfterSolar > 0 ? (
										<DoubleBarsWithValue
											height={heightOfResidualBlock}
											value={decimalFreeNumber(data?.totalResidualBill25Years)}
											subtext={t("SolarReport.residualBillText")}
											height2={heightOfSavingsBlock}
											value2={decimalFreeNumber(data?.switchToSolar)}
											subtext2={t("SolarReport.solarLoanText")}
											heading={t("SolarReport.switchToSolarText")}
											totalValue={decimalFreeNumber(data?.electricAndResidualBill)}
										/>
									) : (
										<BarWithValue
											height={heightOfSavingsBlock}
											value={decimalFreeNumber(data?.switchToSolar)}
											subtext={t("SolarReport.switchToSolarText")}
											isSolar
										/>
									)}
									<BarWithValue
										height={heightOfElectricBlock}
										value={decimalFreeNumber(data?.totalAnnualBill20years)}
										subtext={t("SolarReport.stayWithElectricityText")}
									/>
								</Box>

								<Box
									display="flex"
									flexDirection="column"
									gap={24}
									mt={42}
									width={{ xs: "100%", sm: "100%" }}
									mx="auto"
								>
									<DashedBoxWithValues
										isWhiteBox
										leftValue={{
											value:
												user.selectedPlan === "Lease"
													? (decimalFreeNumber(data?.solarSavingYear25Lease) as any)
													: (decimalFreeNumber(data?.solarSavingYear25) as any),
											// decimalFreeNumber(data?.solarSavingYear25) as any,
											// data?.trueSolarSaving25years
											text: t("SolarReport.year25CostOfDoingNothing"),
										}}
									/>
								</Box>
							</Box>
						)}
					</SolarReportRightColumn>
				)}

				{isSmallScreen && (
					<Box className="fade-in-block" sx={{ p: { xs: 0, sm: 40 }, my: "auto" }}>
						<Box display="flex" alignItems="flex-end" justifyContent="center" gap={{ xs: 20, sm: 70 }}>
							{data?.UtilityBillAfterSolar > 0 ? (
								<DoubleBarsWithValue
									height={heightOfResidualBlock}
									value={decimalFreeNumber(data?.totalResidualBill25Years)}
									subtext={t("SolarReport.residualBillText")}
									height2={heightOfSavingsBlock}
									value2={decimalFreeNumber(data?.switchToSolar)}
									subtext2={t("SolarReport.solarLoanText")}
									heading={t("SolarReport.switchToSolarText")}
									totalValue={decimalFreeNumber(data?.electricAndResidualBill)}
								/>
							) : (
								<BarWithValue
									height={heightOfSavingsBlock}
									value={decimalFreeNumber(data?.switchToSolar)}
									subtext={t("SolarReport.switchToSolarText")}
									isSolar
								/>
							)}
							<BarWithValue
								height={heightOfElectricBlock}
								value={decimalFreeNumber(data?.totalAnnualBill20years)}
								subtext={t("SolarReport.stayWithElectricityText")}
							/>
						</Box>

						<Box display="flex" flexDirection="column" gap={24} mt={42} width={{ xs: "100%", sm: "100%" }} mx="auto">
							<DashedBoxWithValues
								isWhiteBox
								leftValue={{
									value:
										user.selectedPlan === "Lease"
											? (decimalFreeNumber(data?.solarSavingYear25Lease) as any)
											: (decimalFreeNumber(data?.solarSavingYear25) as any),
									// data?.trueSolarSaving25years
									text: t("SolarReport.year25CostOfDoingNothing"),
								}}
							/>
						</Box>
					</Box>
				)}
			</SolarReportContainer>

			{isSmallScreen &&
				(!forDashboard ? (
					<CustomButton
						fullWidth
						onClick={handleNext}
						sx={{ width: { xs: "calc(100% - 16px - 16px)", md: "calc(100% - 32px - 32px)" }, mx: 16, mb: 32 }}
					>
						{textSnippets.unlock_proposal_button || "See if I qualify"}
					</CustomButton>
				) : (
					<></>
				))}

			<QualificationDialog open={showDialog} onClose={closeDialog} bill={user?.bill} />
		</>
	);
};

export default SolarReport;
